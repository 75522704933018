<template>
  <b-row v-if="!dataLoad">
    <b-col
      v-if="localDataLoad"
      cols="12"
      class="d-flex align-items-center justify-content-center "
    >
      <b-spinner label="Loading..." />
    </b-col>
    <b-col
      cols="6"
    >

      <b-form-group
        v-if="!localDataLoad"
        label="office "
      >
        <b-form-select
          v-model="office_id"
          :options="optionsOffice"
          trim
          @change="$emit('handleOfficeUser', office_id)"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  props: {
    dataLoad: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      optionsOffice: [],
      office_id: '',
      localDataLoad: false,

    }
  },

  mounted() {
    this.getOffice()
  },
  methods: {

    getOffice() {
      this.localDataLoad = true
      axios.get('office').then(res => {
        const offices = decryptData(res.data.payload).data.offices.map(item => ({
          value: item.id,
          text: item.name,
        }))
        this.optionsOffice = [{ value: null, text: 'All Offices' }, ...offices]
      }).finally(() => {
        this.localDataLoad = false
      })
    },
  },
}
</script>
