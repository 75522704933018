<template>

  <b-row
    v-if="ShowDateInput"
    class="mb-2"
  >
    <b-col
      v-if="!dataLoad"
      cols="6"
      md="6"
    >
      <div>
        <label for="datepicker-full-width">From : </label>
        <b-form-datepicker
          id="datepicker-full-width-from"
          v-model="LocalFromDate"
          today-button
          reset-button
          menu-class="w-100"
          calendar-width="100%"
          class="datepicker-full-width mb-2"
          @input="handleFormDataChange"
        />
      </div>
    </b-col>
    <b-col
      v-if="!dataLoad"
      cols="6"
      md="6"
    >
      <div>
        <label for="datepicker-full-width">To : </label>
        <b-form-datepicker
          id="datepicker-full-width-to"
          v-model="LocalToDate"
          today-button
          reset-button
          menu-class="w-100"
          calendar-width="100%"
          class="datepicker-full-width mb-2"
          @input="handleFormDataChange"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'SelectDateReq',
  props: {
    ShowDateInput: {
      type: Boolean,
      default: false,
    },
    dataLoad: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      LocalFromDate: null,
      LocalToDate: null,
    }
  },
  methods: {
    handleFormDataChange() {
      // console.log(this.LocalFromDate, this.LocalToDate)
      this.$emit('handleDate', this.LocalFromDate, this.LocalToDate)
    },
  },
}
</script>

<style lang="scss">
.datepicker-full-width{
  button{
    color: #0081f2 !important;;
  }
}
</style>
