<template>
  <span v-if="!isLoading">
    <img
      v-if="!dataLoad"
      src="@/assets/images/icons/excel.png"
      style="width: 70px; height: 70px; cursor: pointer;"
      @click="makeSheetExcelRequest"
    >
  </span>
  <span
    v-else
    class="d-flex align-items-center justify-content-center"
  >
    <b-spinner label="Loading..." />
  </span>
</template>
<script>

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    dataLoad: {
      type: Boolean,
      default: () => false,
    },
    paramExel: {
      type: Object,
      default: () => {},
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },

  methods: {

    async makeSheetExcelRequest() {
      this.isLoading = true
      const headersInParams = this.columns.map(column => column.key).join(',')

      const newParamsExcel = Object.fromEntries(
        Object.entries(this.paramExel).filter(([key, value]) => key !== 'skip' && value != null),
      )
      try {
        const response = await axios.get('requests', {
          params: {
            excel: 1,
            headers: headersInParams,
            ...newParamsExcel,
          },
          responseType: 'blob',
        })

        if (response.status === 200) {
          const now = new Date()
          const formattedDate = now.toISOString().split('T')[0]
          const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '-')
          const dateTimeString = `request ${formattedDate}_${formattedTime}`

          const blob = new Blob([response.data], { type: response.data.type })
          const url = window.URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          link.download = dateTimeString
          link.click()

          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${error}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

  },
}
</script>
