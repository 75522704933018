<template>

  <span title="Edit">
    <feather-icon
      icon="EditIcon"
      class="cursor-pointer"
      @click="
        editToPage(
          data.item.id ,
          data.item.name,
          data.item.name.en
        )
      "
    />
  </span>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    editComponent: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => 'page',
    },
  },

  methods: {
    editToPage(id, name) {
      const formattedName = typeof name === 'object' && name.en ? name.en : name

      if (this.type === 'page') {
        this.$router.push({ name: this.editComponent, params: { id, name: formattedName } })
      } else {
        this.$parent.$refs.genmodal.show(id)
        this.$store.commit('generalIds/SET_ID', id)
      }
    },

  },

}
</script>
